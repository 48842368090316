'use strict';

var $ = window.$;

$(document).ready(function () {

  $('#requestForm').on('submit', function (e) {

    e.preventDefault();

    $('[data-remodal-id="modal-success"]').remodal().open();


  });

  window.VMasker(document.querySelectorAll('input[name=phone]')).maskPattern('+9 (999) 999-9999');

  $('input[name=phone]').on('click', function () {
    if ($(this).val() === '') {
      $(this).val('+7');
    }
  });


  $('.js-openMenu').on('click', function (e) {
    $(this).toggleClass('isOpen');
    $('#mobileMenu').toggleClass('isOpen');
  });

  $('body').on('click', function (e) {
    var menuButton = $('.js-openMenu');
    var mobileMenu = $('#mobileMenu');
    if (!menuButton.is(e.target) && menuButton.has(e.target).length === 0 && !mobileMenu.is(e.target) && mobileMenu.has(e.target).length === 0) {
      $('.js-openMenu').removeClass('isOpen');
      $('#mobileMenu').removeClass('isOpen');
    }
  });


  $('.js-dropDown').on('click', function () {
    $(this).toggleClass('isOpen');
    $(this).siblings('.dropDownMenu__content').slideToggle(200);
  });


  if ($(window).width() > 1024 && $('section').is('#calcBlock')) {
    var lastScrollTop = 0;
    var wrapper = $('.awPageWrapper');
    var fixedDashboard = $('#headDashboard');
    var calcBlockPos = $('#calcBlock').offset().top;
    var calcBlockHeight = $('#calcBlock').height();

    $(window).scroll(function (event) {
      var st = $(this).scrollTop();

      if (st > fixedDashboard.height() + 100 && st < calcBlockPos + calcBlockHeight + 200) {
        fixedDashboard.addClass('isFirst');
      } else {
        fixedDashboard.removeClass('isFirst');
      }

      if (st > calcBlockPos + calcBlockHeight) {
        wrapper.addClass('awPageWrapper_fixedDashboard');
        fixedDashboard.addClass('isShow');

        if (st > lastScrollTop) {
          fixedDashboard.removeClass('isOpen');
        } else {
          fixedDashboard.addClass('isOpen');
        }
        lastScrollTop = st;
      } else {
        wrapper.removeClass('awPageWrapper_fixedDashboard');
        fixedDashboard.removeClass('isShow');
        fixedDashboard.removeClass('isOpen');
      }
    });
  } else if ($('div').is('#asideCalc')) {
    var calcAside = $('#asideCalc');
    var calcAsidePos = calcAside.offset().top;

    $(window).scroll(function (event) {
      var st = $(this).scrollTop();
      if (st >= calcAsidePos) {
        calcAside.addClass('isFixed');
      } else {
        calcAside.removeClass('isFixed');
      }
    });
  }

  $("body").on("click", ".js-upper", function (event) {
    $('html, body').animate({ scrollTop: 0 }, 700);
  });

  var fixHeightElements = function fixHeightElements(el) {
    var elementsToFixHeight = el.find('.lslide');
    var myArr = [];
    $.each($(elementsToFixHeight), function (index, item) {
      myArr.push($(item).innerHeight());
    });
    var maxHeight = Math.max.apply(null, myArr);
    el.css('min-height', maxHeight + 5 + 'px');
    elementsToFixHeight.css('height', maxHeight + 5 + 'px');
  };

  var customTogglers = function customTogglers(el) {
    var sliderOuter = el.parents('.slider__outer');
    var togglerLeft = sliderOuter.find('.slider__toggler_left');
    var togglerRight = sliderOuter.find('.slider__toggler_right');
    togglerLeft.click(function () {
      el.goToPrevSlide();
    });
    togglerRight.click(function () {
      el.goToNextSlide();
    });
  };

  var checkSlidesLength = function checkSlidesLength(el) {
    var slidesLenght = el.find('.slider__item').length;
    var slidesWidth = el.find('.slider__item').width();
    var outherWidth = el.parents('.slider__outer').width();
    var slidesQuantity = Math.round(outherWidth / slidesWidth);

    if (slidesQuantity >= slidesLenght) {
      el.parents('.slider__outer').addClass('slider__outer_underMinimal');
      slider.destroy();
    } else {
      el.parents('.slider__outer').addClass('slider__outer_arrow');
    }
  };

  $('.js_defaultSlider').lightSlider({
    item: 4,
    slideMargin: 30,
    speed: 700,
    pager: false,
    loop: false,
    enableDrag: false,
    controls: false,
    slideMove: 1,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    responsive: [{
      breakpoint: 1024,
      settings: {
        item: 3
      }
    }, {
      breakpoint: 768,
      settings: {
        item: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        item: 1
      }
    }],
    onSliderLoad: function onSliderLoad(el) {
      customTogglers(el);

      var elementsToFixHeight = el.find('.lslide');
      var myArr = [];
      $.each($(elementsToFixHeight), function (index, item) {
        myArr.push($(item).innerHeight());
      });
      var maxHeight = Math.max.apply(null, myArr);
      el.css({ 'height': maxHeight + 5 + 'px' });
      el.find('.lslide').show();
      checkSlidesLength(el);
    }
  });

  $('.js_reviewsSlider').lightSlider({
    item: 2,
    slideMargin: 60,
    speed: 700,
    pager: true,
    loop: false,
    enableDrag: false,
    controls: false,
    slideMove: 1,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    responsive: [{
      breakpoint: 480,
      settings: {
        item: 1
      }
    }],
    onSliderLoad: function onSliderLoad(el) {
      customTogglers(el);

      var elementsToFixHeight = el.find('.lslide');
      var myArr = [];
      $.each($(elementsToFixHeight), function (index, item) {
        myArr.push($(item).innerHeight());
      });
      var maxHeight = Math.max.apply(null, myArr);
      el.css({ 'height': maxHeight + 5 + 'px' });
      el.find('.lslide').show();
      checkSlidesLength(el);
    }
  });
});